<template>
    <div>
        <fieldset class="mt-2">
            <legend class="text-blue">
                {{ $t("loanRepayment.repaymentInfo") }}
            </legend>
            <div class="row">
                <div class="col-md-12">
                    <div class="row mb-2">
                        <div class="col-lg-3">
                            <label
                                class="form-col-label control-label required"
                                >{{
                                    $t("loanRepayment.actualpaymentDate")
                                }}</label
                            >
                            <DatePicker
                                :value="model.actual_payment_date"
                                placeholder="DD-MM-YYYY"
                                format="dd-MM-yyyy"
                                @on-change="onChangeActualPaymentDate"
                                :options="options"
                                style="width: 100%;"
                            ></DatePicker>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('actual_payment_date')"
                            >
                                {{ errors.first("actual_payment_date") }}
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <label
                                class="form-col-label control-label required"
                                >{{ $t("loanRepayment.paymentType") }}</label
                            >
                            <select
                                v-model.number="model.payment_type"
                                class="form-select form-select-sm"
                                :class="{
                                    'is-invalid': errors.has('payment_type')
                                }"
                                @change="fetchRepayment"
                                disabled
                            >
                                <option
                                    :value="null"
                                    disabled
                                    selected
                                    style="display: none;"
                                    >{{ $t("select") }}</option
                                >
                                <option
                                    v-for="(option, index) in paymentType"
                                    :value="option.id"
                                    :key="index"
                                >
                                    {{ option.label }}
                                </option>
                            </select>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('payment_type')"
                            >
                                {{ errors.first("payment_type") }}
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <label class="form-col-label control-label">{{
                                $t("loanRepayment.coName")
                            }}</label>
                            <Select
                                v-model="employee_id"
                                multiple
                                :max-tag-count="1"
                                :filterable="true"
                                :filter-by-label="true"
                                @on-change="onChangeEmployee"
                            >
                                <Option
                                    v-for="item in employees"
                                    :value="item.employee_id"
                                    :key="item.employee_id"
                                    :label="item.employee_name_en"
                                >
                                    {{ item.employee_name_en }}
                                </Option>
                            </Select>
                        </div>
                        <div class="col-lg-3">
                            <div class="tw-mt-4">
                                <Input
                                    search
                                    v-model="search"
                                    :placeholder="
                                        $t('loanRepayment.searchLoan')
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="demo-spin-article">
                        <div class="tw-overflow-auto" style="height: 60vh;">
                            <div
                                class="mt-2"
                                v-for="(items, key) in groupBy(
                                    loanDisbursementsLists,
                                    'contract_number'
                                )"
                                :key="key"
                            >
                                <div class="panel">
                                    <div
                                        class="panel-heading bg-cyan-700 text-white tw-flex tw-items-center tw-justify-between"
                                    >
                                        <div
                                            class="tw-flex tw-justify-start tw-space-x-2"
                                        >
                                            {{
                                                $t(
                                                    "loanRepayment.contractNumber"
                                                )
                                            }}:&nbsp;
                                            <a
                                                class="tw-no-underline text-white tw-font-bold"
                                                v-clipboard:copy="key"
                                                v-clipboard:success="onCopy"
                                                v-clipboard:error="onCopyError"
                                                v-tooltip="copied"
                                            >
                                                {{ key }}</a
                                            >
                                        </div>
                                        <div
                                            class="tw-flex tw-justify-end tw-space-x-2"
                                        >
                                            {{
                                                $t(
                                                    "loanRepayment.totalAmountPerGroup"
                                                )
                                            }}:&nbsp;
                                            <span
                                                class="badge bg-blue fs-14px"
                                                >{{
                                                    formatCurrencyWithCode(
                                                        totalPerGroup(items),
                                                        currency
                                                    )
                                                }}</span
                                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            {{
                                                $t(
                                                    "loanRepayment.totalAmountPaid"
                                                )
                                            }}:&nbsp;
                                            <span
                                                class="badge bg-success fs-14px"
                                                >{{
                                                    formatCurrencyWithCode(
                                                        totalAmountPaidPerGroup(
                                                            items
                                                        ),
                                                        currency
                                                    )
                                                }}</span
                                            >
                                        </div>
                                    </div>
                                    <div
                                        class="list-group-item d-flex"
                                        v-for="(item, i) in sort(
                                            items,
                                            'group_id'
                                        )"
                                        :key="i"
                                    >
                                        <div class="me-1 fs-14px text-center">
                                            <img
                                                v-lazy="{
                                                    src: photo(item.customer),
                                                    error: onError(
                                                        item.customer
                                                    ),
                                                    loading: require('@/assets/Rolling-1s-200px.svg'),
                                                    preLoad: 1.3,
                                                    attempt: 1
                                                }"
                                                width="64"
                                                height="64"
                                                class="tw-rounded"
                                            />
                                            <small class="text-gray-600">{{
                                                item.customer.customer_code
                                                    ? item.customer
                                                          .customer_code
                                                    : "-"
                                            }}</small>
                                        </div>
                                        <div class="flex-fill">
                                            <div
                                                class="tw-grid tw-grid-cols-3 tw-gap-3"
                                            >
                                                <div
                                                    class="fs-16px lh-12 mb-2px fw-bold text-blue"
                                                >
                                                    {{
                                                        cycleDateInDay(
                                                            item.cycle_payment_date
                                                        )
                                                    }}
                                                    {{
                                                        item.cycle_payment_date
                                                    }}
                                                </div>
                                                <div class="text-gray-600">
                                                    {{
                                                        $t(
                                                            "loanRepayment.loanType"
                                                        )
                                                    }}:
                                                    <span>{{
                                                        item.loan_type
                                                            ? $root.$i18n
                                                                  .locale ===
                                                              "kh"
                                                                ? item.loan_type
                                                                      .loan_type_kh
                                                                : item.loan_type
                                                                      .loan_type_en
                                                            : "-"
                                                    }}</span>
                                                </div>
                                            </div>
                                            <div
                                                class="tw-grid tw-grid-cols-3 tw-gap-3"
                                            >
                                                <div class="fs-12px">
                                                    <div
                                                        class="text-gray-600 flex-1"
                                                    >
                                                        {{
                                                            $t(
                                                                "loanRepayment.loanNumber"
                                                            )
                                                        }}:
                                                        <a
                                                            class="tw-p-2 tw-no-underline text-info tw-font-bold"
                                                            v-clipboard:copy="
                                                                item.loan_number
                                                            "
                                                            v-clipboard:success="
                                                                onCopy
                                                            "
                                                            v-clipboard:error="
                                                                onCopyError
                                                            "
                                                            v-tooltip="copied"
                                                            >{{
                                                                item.loan_number
                                                            }}</a
                                                        >
                                                        {{
                                                            $t(
                                                                "customerLoanRequest.groupId"
                                                            )
                                                        }}:
                                                        <span
                                                            class="badge bg-info"
                                                            >{{
                                                                item.group_id
                                                                    ? item.group_id
                                                                    : "-"
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                                <div class="text-gray-600">
                                                    {{
                                                        $t(
                                                            "loanRepayment.repaymentMethod"
                                                        )
                                                    }}:
                                                    <span>{{
                                                        item.repayment_method
                                                            ? $root.$i18n
                                                                  .locale ===
                                                              "kh"
                                                                ? item
                                                                      .repayment_method
                                                                      .method_name_kh
                                                                : item
                                                                      .repayment_method
                                                                      .method_name_en
                                                            : "-"
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="text-gray-600 tw-text-right"
                                                >
                                                    <span
                                                        class="badge bg-blue fs-14px"
                                                        >{{
                                                            formatCurrencyWithCode(
                                                                item.total_amount,
                                                                currency
                                                            )
                                                        }}</span
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                class="tw-grid tw-grid-cols-3 tw-gap-3"
                                            >
                                                <div class="mb-1 fs-12px">
                                                    <div
                                                        class="text-gray-600 flex-1"
                                                    >
                                                        {{
                                                            $t(
                                                                "loanRepayment.customerName"
                                                            )
                                                        }}:
                                                        {{
                                                            $root.$i18n
                                                                .locale === "kh"
                                                                ? item.customer
                                                                      .customer_name_kh
                                                                : item.customer
                                                                      .customer_name_en
                                                        }}
                                                    </div>
                                                </div>
                                                <div class="text-gray-600">
                                                    {{
                                                        $t("loanRepayment.co")
                                                    }}:
                                                    <span>{{
                                                        item.co
                                                            ? $root.$i18n
                                                                  .locale ===
                                                              "kh"
                                                                ? item.co
                                                                      .employee_name_kh
                                                                : item.co
                                                                      .employee_name_en
                                                            : "-"
                                                    }}</span>
                                                </div>
                                            </div>
                                            <div
                                                class="tw-grid tw-grid-cols-3 tw-gap-3"
                                            >
                                                <div class="text-gray-600">
                                                    {{
                                                        $t(
                                                            "customer.contactNo"
                                                        )
                                                    }}:
                                                    {{
                                                        item.customer.contact_no
                                                            ? item.customer
                                                                  .contact_no
                                                            : "-"
                                                    }}
                                                </div>
                                                <div class="text-gray-600">
                                                    {{
                                                        $t(
                                                            "loanRepayment.isOweCyclePaid"
                                                        )
                                                    }}:
                                                    <span
                                                        class="badge bg-warning"
                                                        v-if="
                                                            item.is_owe_cycle_paid
                                                        "
                                                        >{{ $t("yes") }}</span
                                                    >
                                                    <span
                                                        v-else
                                                        class="badge bg-secondary"
                                                        >{{ $t("no") }}</span
                                                    >
                                                </div>
                                                <div
                                                    class="text-gray-600 tw-text-right"
                                                >
                                                    <Poptip trigger="focus">
                                                        <Input
                                                            :placeholder="
                                                                $t(
                                                                    'loanRepayment.actualPaymentAmount'
                                                                )
                                                            "
                                                            v-model.number="
                                                                item.amount_paid
                                                            "
                                                            :class="{
                                                                'ivu-form-item-error': errors.has(
                                                                    'amount_paid'
                                                                )
                                                            }"
                                                            size="large"
                                                            type="number"
                                                            clearable
                                                            @on-clear="
                                                                () =>
                                                                    (item.amount_paid = null)
                                                            "
                                                            @on-keyup="
                                                                () =>
                                                                    item.amount_paid ==
                                                                    ''
                                                                        ? (item.amount_paid = null)
                                                                        : (item.amount_paid =
                                                                              item.amount_paid)
                                                            "
                                                        >
                                                            <i
                                                                class="fas fa-dollar-sign"
                                                                slot="prepend"
                                                                v-if="
                                                                    item.currency_id ==
                                                                        1
                                                                "
                                                            ></i>
                                                            <i
                                                                class="far fa-registered"
                                                                slot="prepend"
                                                                v-if="
                                                                    item.currency_id ==
                                                                        2
                                                                "
                                                            ></i>
                                                            <i
                                                                class="fas fa-bold"
                                                                slot="prepend"
                                                                v-if="
                                                                    item.currency_id ==
                                                                        3
                                                                "
                                                            ></i>
                                                            <Button
                                                                @click="
                                                                    () =>
                                                                        (item.amount_paid =
                                                                            item.total_amount)
                                                                "
                                                                slot="append"
                                                                icon="md-return-left"
                                                                type="primary"
                                                                ghost
                                                            ></Button>
                                                        </Input>
                                                        <div slot="content">
                                                            {{
                                                                item.amount_paid
                                                                    ? formatNumber(
                                                                          item.amount_paid,
                                                                          item.currency_id
                                                                      )
                                                                    : $t(
                                                                          "loanRepayment.enterNumber"
                                                                      )
                                                            }}
                                                        </div>
                                                    </Poptip>
                                                    <Input
                                                            :placeholder="
                                                                $t(
                                                                    'loanRepayment.refVoucher'
                                                                )
                                                            "
                                                            v-model="
                                                                item.ref_voucher
                                                            "
                                                            :class="{
                                                                'ivu-form-item-error': errors.has(
                                                                    'ref_voucher'
                                                                )
                                                            }"
                                                            size="large"
                                                            style="width: 270px" 
                                                        >
                                                        </Input>
                                                </div>
                                            </div>
                                            <hr class="mb-10px bg-gray-600" />
                                            <div
                                                class="d-flex align-items-center mb-5px"
                                            >
                                                <div
                                                    class="fs-12px d-flex align-items-center text-dark fw-bold"
                                                >
                                                    {{
                                                        $t(
                                                            "loanRepayment.repaymentItems"
                                                        )
                                                    }}
                                                </div>
                                                <div class="ms-auto">
                                                    <a
                                                        v-b-toggle
                                                        :href="
                                                            '#accordion' +
                                                                key +
                                                                i
                                                        "
                                                        @click.prevent
                                                        class="btn btn-outline-default text-gray-600 btn-xs rounded-pill fs-10px px-2 not-collapsed"
                                                        style="overflow-anchor: none;"
                                                    >
                                                        {{
                                                            $t(
                                                                "loanRepayment.collapse"
                                                            )
                                                        }}
                                                    </a>
                                                </div>
                                            </div>
                                            <b-collapse
                                                :id="'accordion' + key + i"
                                                :visible="false"
                                                accordion="my-accordion"
                                            >
                                                <!-- begin widget-list -->
                                                <div
                                                    class="widget-list rounded"
                                                >
                                                    <!-- begin widget-list-item -->
                                                    <a
                                                        href="#"
                                                        class="widget-list-item"
                                                    >
                                                        <div
                                                            class="widget-list-content"
                                                        >
                                                            <h4
                                                                class="widget-list-title"
                                                            >
                                                                1 Overdue
                                                                Penalty
                                                            </h4>
                                                        </div>
                                                        <div
                                                            class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                                                        >
                                                            {{
                                                                formatCurrencyWithCode(
                                                                    item.overdue_penalty,
                                                                    item.currency
                                                                )
                                                            }}
                                                        </div>
                                                    </a>
                                                    <a
                                                        href="#"
                                                        class="widget-list-item"
                                                    >
                                                        <div
                                                            class="widget-list-content"
                                                        >
                                                            <h4
                                                                class="widget-list-title"
                                                            >
                                                                2 Monthly Fee
                                                            </h4>
                                                        </div>
                                                        <div
                                                            class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                                                        >
                                                            {{
                                                                formatCurrencyWithCode(
                                                                    item.monthly_fee,
                                                                    item.currency
                                                                )
                                                            }}
                                                        </div>
                                                    </a>
                                                    <a
                                                        href="#"
                                                        class="widget-list-item"
                                                    >
                                                        <div
                                                            class="widget-list-content"
                                                        >
                                                            <h4
                                                                class="widget-list-title"
                                                            >
                                                                3 Interest
                                                            </h4>
                                                        </div>
                                                        <div
                                                            class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                                                        >
                                                            {{
                                                                formatCurrencyWithCode(
                                                                    item.interest,
                                                                    item.currency
                                                                )
                                                            }}
                                                        </div>
                                                    </a>
                                                    <a
                                                        href="#"
                                                        class="widget-list-item"
                                                    >
                                                        <div
                                                            class="widget-list-content"
                                                        >
                                                            <h4
                                                                class="widget-list-title"
                                                            >
                                                                4 Principal
                                                            </h4>
                                                        </div>
                                                        <div
                                                            class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                                                        >
                                                            {{
                                                                formatCurrencyWithCode(
                                                                    item.principal,
                                                                    item.currency
                                                                )
                                                            }}
                                                        </div>
                                                    </a>
                                                    <!-- end widget-list-item -->
                                                </div>
                                                <!-- end widget-list -->
                                            </b-collapse>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="invoice">
                            <div class="invoice-content">
                                <div class="invoice-price">
                                    <div class="invoice-price-right">
                                        <small>GRAND TOTAL</small>
                                        <span class="fw-bold">{{
                                            formatCurrencyWithCode(
                                                totalAmountPaid,
                                                currency
                                            )
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Spin size="large" fix v-if="loanLoading"> </Spin>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapGetters, mapState } from "vuex";
import { orderBy, debounce, sumBy } from "lodash";
import moment from "moment";

export default {
    name: "loanRepaymentByGroup",
    props: ["value", "validation"],
    data() {
        return {
            model: {},
            loading: false,
            loanLoading: false,
            errors: new Errors(),
            repaymentItems: [],
            employee_id: [],
            copied: "Click to copy",
            paymentType: [
                {
                    id: 1,
                    label: "Repayment"
                },
                {
                    id: 3,
                    label: "Pay Off"
                }
            ],
            options: {
                disabledDate(date) {
                    return date.valueOf() > Date.now();
                }
            },
            search: null,
            loanDisbursementsList: []
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("creditOperation/loanRepayment", [
            "edit_data",
            "loanDisbursements",
            "employees"
        ]),
        ...mapGetters(["formatNumber", "formatCurrencyWithCode"]),
        currency() {
            if (this.model.loan_id) {
                let dis = this.loanDisbursements.find(
                    l => l.loan_id === this.model.loan_id
                );
                if (dis) {
                    return dis.currency;
                }
                return {
                    currency_id: this.$store.state.authUser.sys_setting[0]
                        .default_currency_id,
                    currency_code: this.$store.state.authUser.sys_setting[0]
                        .default_currency
                };
            }
            return {
                currency_id: this.$store.state.authUser.sys_setting[0]
                    .default_currency_id,
                currency_code: this.$store.state.authUser.sys_setting[0]
                    .default_currency
            };
        },
        payOnlyCheckItems() {
            if (this.repaymentItems.length > 0) {
                return this.repaymentItems.filter(r => r.is_pay === true);
            }
            return [];
        },
        subtotal() {
            if (this.payOnlyCheckItems.length > 0) {
                return this.payOnlyCheckItems.reduce(
                    (n, { total_amount }) => n + total_amount,
                    0
                );
            }
            return 0;
        },
        totalPayOffPenalty() {
            if (this.payOnlyCheckItems.length > 0) {
                if (this.model.payment_type == 3) {
                    //pay-off
                    return this.payOnlyCheckItems.reduce(
                        (n, { pay_off_penalty_amount }) =>
                            n + pay_off_penalty_amount,
                        0
                    );
                }
                return 0;
            }
            return 0;
        },
        totalAmountExcess() {
            if (this.payOnlyCheckItems.length > 0) {
                return this.payOnlyCheckItems.reduce(
                    (n, { amount_excess }) => n + amount_excess,
                    0
                );
            }
            return 0;
        },
        loanDisbursementsLists() {
            return this.loanDisbursementsList;
        },
        totalAmountPaid() {
            return sumBy(this.loanDisbursementsList, "amount_paid");
        }
    },
    methods: {
        ...mapActions("creditOperation/loanRepayment", [
            "getLoanDailyCollect",
            "getEmployees"
        ]),
        loadLoanDisbursement: debounce(function(query) {
            if (this.employees.length <= 0) {
                this.getEmployees();
            }
            this.loanLoading = true;
            this.getLoanDailyCollect({
                search: query
            }).finally(() => {
                this.loanDisbursementsList = this.loanDisbursements;
                this.loanLoading = false;
            });
        }, 1000),
        fetchRepayment() {
            if (
                this.model.cycle_payment_date &&
                this.model.loan_id &&
                this.model.payment_type
            ) {
                this.loading = true;
                this.$store
                    .dispatch("creditOperation/loanRepayment/fetchRepayment", {
                        loan_id: this.model.loan_id,
                        actual_payment_date: this.model.actual_payment_date,
                        cycle_payment_date: this.model.cycle_payment_date,
                        payment_type: this.model.payment_type
                    })
                    .then(response => {
                        this.repaymentItems = response.data;
                    })
                    .catch(error => {
                        this.errors = new Errors(error.errors);
                        this.notice({ type: "error", text: error.message });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        onChangeActualPaymentDate(date) {
            this.model.actual_payment_date = date;
        },
        onChangeCyclePaymentDate(date) {
            this.loanLoading = true;
            this.repaymentItems = [];
            this.model.cycle_payment_date = date;
            this.getLoanDailyCollect({
                cycle_payment_date: this.model.cycle_payment_date
            })
                .then(() => {
                    if (this.loanDisbursements.length == 0) {
                        this.model.loan_id = null;
                    }
                })
                .finally(() => {
                    this.loanLoading = false;
                });
            this.fetchRepayment();
        },
        itemDetails(items) {
            return orderBy(items, ["payment_priority"], ["asc"]);
        },
        photo(record) {
            if (record) {
                return record.photo;
            }

            return require("@/assets/staff-default-man.png");
        },
        onError(record) {
            if (record) {
                return record.gender == "Male"
                    ? require("@/assets/staff-default-man.png")
                    : require("@/assets/staff-default-women.png");
            }

            return require("@/assets/staff-default-man.png");
        },
        cycleDateInDay(date) {
            if (date) {
                return moment(date, "DD-MM-YYYY").format("ddd");
            }

            return "";
        },
        groupBy(array, key) {
            const result = {};
            array.forEach(item => {
                if (!result[item[key]]) {
                    result[item[key]] = [];
                }
                result[item[key]].push(item);
            });
            return result;
        },
        sort(array, key) {
            return orderBy(array, [key], ["asc"]);
        },
        onCopy: function() {
            this.copied = "Copied";
            setTimeout(() => {
                this.copied = "Click to copy";
            }, 1000);
        },
        onCopyError: function() {
            this.copied = "Failed copied";
        },
        totalPerGroup(items) {
            return sumBy(items, "total_amount");
        },
        totalAmountPaidPerGroup(items) {
            return sumBy(items, "amount_paid");
        },
        onChangeEmployee(value) {
            this.loanLoading = true;
            this.getLoanDailyCollect({
                filter: {
                    co_id: value
                }
            }).finally(() => {
                this.loanDisbursementsList = this.loanDisbursements;
                this.loanLoading = false;
            });
        }
    },
    watch: {
        validation: {
            handler() {
                this.errors = new Errors(this.validation.errors);
            },
            deep: true
        },
        payOnlyCheckItems: {
            handler() {
                this.model.amount_paid = this.subtotal - this.totalAmountExcess;
            },
            deep: true
        },
        search: debounce(function(search) {
            this.loanLoading = true;
            this.getLoanDailyCollect({
                filter: {
                    search: search,
                    co_id: this.employee_id
                }
            }).finally(() => {
                this.loanDisbursementsList = this.loanDisbursements;
                this.loanLoading = false;
            });
        }, 1000)
    }
};
</script>
<style scoped>
.invoice {
    padding: 0 !important;
}
.widget-list .widget-list-item .widget-list-media,
.widget-list .widget-list-item .widget-list-content,
.widget-list .widget-list-item .widget-list-action {
    padding: 0.2rem 0.8rem;
}
.invoice .invoice-price .invoice-price-left {
    padding: 14px;
    font-size: 14px;
}
.invoice .invoice-price .invoice-price-right {
    padding: 14px;
    font-size: 14px;
}
.invoice .invoice-price .invoice-price-right small,
.invoice .invoice-price .invoice-price-right .small {
    top: 5px;
}
hr {
    margin: 0.5rem 0;
}
</style>
